.exportdialog .upload-workers {
  height: 30px;
}
.exportdialog .upload-workers .el-upload {
  height: 30px !important;
  width: 97px;
  border: none !important;
}
.exportdialog .upload-workers .el-upload .el-button {
  padding: 5px 0;
}
